/* eslint-disable react/prop-types */
import {
  ADMVessel,
  BoraTheme,
  Fleet,
  FooterItems,
  MenuItem,
  News,
  PageProps,
  RouteImages,
  SailPackage,
  SiteInfo,
} from '@/root/shared-types'
import { GetServerSideProps, NextPage } from 'next'
import dynamic from 'next/dynamic'
import { apiAxios, cmsAxios } from '@/configs'
import { admaritime, bluewave } from '@/root/shared-consts/theme'
import { useConfig } from '@/root/shared-hooks'
import { AxiosError } from 'axios'
import { getCurrentConfig } from '@/configs/theme'
import { SailPackagesGroup } from '@/root/shared-types/reservation/reservation-types'
import { getRoutesPath, getSailPackagesCompactPath } from '@/api/endpoints'

const AdmaritimeMainPage = dynamic(() => import('@/root/pages/admaritime').then((module) => module.MainPage))
const BluewaveMainPage = dynamic(() => import('@/root/pages/bluewave').then((module) => module.MainPage))

const MainPage: NextPage<PageProps> = (props) => {
  const { footerItems, menuItems, fleet, news, siteInfos, sailPackages, routes, errorMessage, sailPackagesGroups } =
    props
  const { theme } = useConfig()

  if (errorMessage) return errorMessage
  switch (theme) {
    case admaritime:
      return (
        <AdmaritimeMainPage
          footerItems={footerItems}
          menuItems={menuItems}
          fleet={fleet}
          news={news}
          siteInfos={siteInfos}
          sailPackages={sailPackages}
          routes={routes}
          sailPackagesGroups={sailPackagesGroups}
        />
      )
    case bluewave:
      return <BluewaveMainPage footerItems={footerItems} menuItems={menuItems} sailPackages={sailPackages} />
    default:
      return null
  }
}

export const getServerSideProps: GetServerSideProps<PageProps> = async ({ locale }) => {
  const theme = process.env.NEXT_PUBLIC_THEME as BoraTheme

  const start = performance.now()
  const { routeImagesSupported } = getCurrentConfig(theme)

  try {
    const { data } = await cmsAxios.get<{ content: FooterItems }>('/footer', {
      params: { _locale: locale },
    })
    const footerItems = data.content

    const { data: menuItems } = await cmsAxios.get<MenuItem[]>('/menu-items', {
      params: { _locale: locale },
    })

    const { data: fleet } = await cmsAxios.get<Fleet<ADMVessel[]>>('/fleet', {
      params: { _locale: locale },
    })

    const { data: news } = await cmsAxios.get<News[]>('/notifications', {
      params: { _locale: locale },
    })
    const { data: siteInfos } = await cmsAxios.get<SiteInfo[]>('/site-infos', {
      params: { _locale: locale },
    })
    let routeImages: RouteImages[] = []
    let sailPackagesGroups: SailPackagesGroup[] = []
    if (routeImagesSupported) {
      const { data: routes } = await cmsAxios.get<RouteImages[]>('/routes', {
        params: { _locale: locale },
      })
      const { data } = await apiAxios.get<SailPackagesGroup[]>(getRoutesPath())
      sailPackagesGroups = data
      routeImages = routes
    }

    const { data: sailPackages } = await apiAxios.get<SailPackage[]>(getSailPackagesCompactPath(), {
      params: { _locale: locale },
    })

    const end = performance.now()
    // eslint-disable-next-line no-console
    console.log(`getServerSideProps took ${end - start} milliseconds`)

    return {
      props: {
        footerItems,
        menuItems: menuItems.sort(({ order: prevOrder }, { order: nextOrder }) => prevOrder - nextOrder),
        fleet,
        news,
        siteInfos,
        sailPackages,
        sailPackagesGroups,
        routes: routeImages,
      },
    }
  } catch (e) {
    const errorMessage = (e as AxiosError).code
    return {
      props: {
        footerItems: {
          lower: [],
          social: [],
          upper: [],
          linkToGroup: [],
        },
        menuItems: [],
        sailPackages: [],
        siteInfos: [],
        routes: [],
        errorMessage,
      },
    }
  }
}

export default MainPage
